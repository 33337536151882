// firebase.config.js
import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAU_-KT7j5Zm7NTth7oiydpDTW27YWvv3Q",
  authDomain: "teach-league.firebaseapp.com",
  projectId: "teach-league",
  storageBucket: "teach-league.appspot.com",
  messagingSenderId: "824406673244",
  appId: "1:824406673244:web:0368c5d6e8222d669ba399",
  measurementId: "G-MFE9EB88W7",
  API_KEY: process.env.GEMINI_API_KEY,
};

// Initialize Firebase only if no app is initialized
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

export const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);

// Export firebaseConfig
export { firebaseConfig };